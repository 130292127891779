<template>
  <div>
    <div class="program-detail" v-if="programDetails">
      <router-link :to="{ name: 'upcoming-programs' }" class="detail-back-btn" @click="$router.back()">
        <unicon name="arrow-left"></unicon>सूची मा फर्कनुहोस्
      </router-link>
      <UpcomingProgram
        :upComingProgram="programDetails"
        :showActionLink="false"
      />
      <section class="program-description">
        <h3>विवरण</h3>
        <div class="program-section-body">
          {{ programDetails.description }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      programDetails: {}
    };
  },
  computed: {
    upcomingProgram() {
      return this.$store.getters.upcomingPrograms;
    }
  },
  components: {
    UpcomingProgram: () => import("./upcomingProgram.vue")
  },
  watch: {
    upcomingProgram: {
      handler: "getProgramDetails",
      immediate: true
    }
  },
  methods: {
    getProgramDetails() {
      let id = this.$route.params.upcomingProgramId;
      this.programDetails = this.$store.state.upcomingPrograms.find((ele) => {
        return ele["s.no."] === id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
