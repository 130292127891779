import { render, staticRenderFns } from "./upcomingProgramDetail.vue?vue&type=template&id=5ee5ace7&scoped=true&"
import script from "./upcomingProgramDetail.vue?vue&type=script&lang=js&"
export * from "./upcomingProgramDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee5ace7",
  null
  
)

export default component.exports